import Vuex from 'vuex'

const setLocalCartList = (state) => {
  const { cartList } = state
  const cartListString = JSON.stringify(cartList)
  localStorage.cartList = cartListString
}

const getLocaCartList = () => {
  // { shopId: {shopName:'', productList:{ productId: {} }}}
  try {
    return JSON.parse(localStorage.cartList);
  } catch(e) {
    return {}
  }
}

// const getSessionDataList = () => {
//   try {
//     return JSON.parse(sessionStorage.data);
//   } catch(e) {
//     return {}
//   }
// }

export default Vuex.createStore({
  state: {
    cartList: getLocaCartList(),
    addressList: [],
    isconnect:'CONNECTED',
    dataList: [],
    themelist: [],
    initData:[],
  },
  mutations: {
    //初始化数据
    changeInitData(state, payload) {
      const { setinitData } = payload;
      state.initData = setinitData;
    },


    //底部connect状态
    changeConnectName(state, payload) {
      const { connectname } = payload;
      state.isconnect = connectname;
    },

    //firmware 数据
    changeDatalist(state, payload) {
      const { result1 } = payload;
      state.dataList = result1;
    },

    //theme 数据
    changeThemelist(state, payload) {
      const { result } = payload;
      state.themelist = result;
    },


    changeCartItemInfo(state, payload) {
      const { shopId, productId, productInfo } = payload
      let shopInfo = state.cartList[shopId] || {
        shopName: '', productList:{}
      }
      let product = shopInfo.productList[productId]
      if(!product) {
        productInfo.count = 0
        product = productInfo
      }
      product.count = product.count + payload.num
      if(payload.num > 0) { product.check = true }
      if(product.count < 0) { product.count = 0 }
      shopInfo.productList[productId] = product
      state.cartList[shopId] = shopInfo
      setLocalCartList(state)
    },
    changeShopName(state, payload) {
      const { shopId, shopName } = payload
      const shopInfo = state.cartList[shopId] || {
        shopName: '', productList:{}
      }
      shopInfo.shopName = shopName
      state.cartList[shopId] = shopInfo
      setLocalCartList(state)
    },
    changeCartItemChecked(state, payload) {
      const { shopId, productId } = payload
      const product = state.cartList[shopId].productList[productId]
      product.check = !product.check
      setLocalCartList(state)
    },
    cleanCartProducts(state, payload) {
      const { shopId } = payload
      state.cartList[shopId].productList = {}
      setLocalCartList(state)
    },
    setCartItemsChecked(state, payload) {
      const { shopId } = payload
      const products = state.cartList[shopId].productList
      if(products) {
        for(let key in products) {
          const product = products[key]
          product.check = true
        }
      }
      setLocalCartList(state)
    },
    clearCartData(state, shopId) {
      state.cartList[shopId].productList = {}
    },
    changeAddressList(state, addressList) {
      state.addressList.splice(0, state.addressList.length, ...addressList)
    }
  }
})
