module.exports = {
    languagename:{
        myname:'ENGLISH'
    },
    // 头部菜单
    header_menu: {
        dashboard: 'SX-PANEL',
        FIRMWARE:'FIRMWARE UPDATES',
        THEMES:'THEMES',
        WALLPAPER:'WALLPAPER',
        SETTINGS:'SETTINGS',
        MANUAL:'MANUAL',
        SUPPORT:'SUPPORT'
    },

    indexlabelname:{
        bannerheadername:'LATEST PRODUCTS'
    },

    updateconfirm:{
        title:"confirms upgrade:",
        message: "UPGRADE ALL \n ENGLISH Ver.",
        confirmButtonText:'CONFIRM',
        cancelButtonText:'CANCEL',
    },
    manualrow:{
        videoname:"VIDEO GUIDE",
        manualname:"MANUAL"
    }
}
