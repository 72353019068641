import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './style/index.scss'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueMoCropper from 'vue-mocropper';
import 'vue-mocropper/dist/mocropper.min.css'; // ！！务必引入CSS
import 'lib-flexible';

/**
 * 引入i18n国际化
 */
 import VueI18n from './i18n'

//websocket
//  import { createSocket } from '@sven0706/websocket'
//  createSocket('wss://image.yihisxminiid.com:9501')


createApp(App).use(VueI18n).use(router).use(store).use(ElementPlus).use(Vant).use(VueMoCropper).mount('#app')

