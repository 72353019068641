import { createRouter, createWebHistory } from 'vue-router'

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home')
  },{
    path: '/g2-manual',
    name: 'explain',
    component: () => import(/* webpackChunkName: "explain" */ '../views/explain/explain')
  }
  ,{
    path: '/wifi',
    name: 'wifi',
    component: () => import(/* webpackChunkName: "explain" */ '../views/wifi/wifi')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
