module.exports = {
    languagename:{
        myname:'简体中文'
    },
    // 头部菜单
    header_menu: {
        dashboard: '仪表盘',
        FIRMWARE:'固件升级',
        THEMES:'主题',
        WALLPAPER:'图片',
        SETTINGS:'设置',
        MANUAL:'手册',
        SUPPORT:'帮助' 
    },

    indexlabelname:{
        bannerheadername:'最新产品'
    },

    updateconfirm:{
        title:"固件升级:",
        message: "全部升级 - 中文版 \n (CHINESE VER.)",
        confirmButtonText:'确认',
        cancelButtonText:'取消',
    },
    manualrow:{
        videoname:"视频指南",
        manualname:"手册"
    }
}
